import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { border, breakpoints, color, font, sizing } from '../../../utils/style';
import { getFile } from '../../../utils/system';

const ReadyToWinWrapper = styled('div')`
  padding-bottom: 15px;
  h3 {
    position: absolute;
    top: 27%;
    left: 2.5%;
    font-size: ${sizing.huge};
    font-weight: ${font.headingWeight};
    color: ${color.blue};
    margin: 0;
  }
  .ready-to__win-btn {
    display: flex;
    align-items: center;
    position: absolute;
    top: 53%;
    left: 2.5%;
    padding: 12px 45px 12px 15px;
    border-radius: 25px;
    background-image: linear-gradient(to left, #0066b2, #2da5fe);
    color: ${color.white};
    img {
      transform: rotate(180deg);
      position: absolute;
      right: 15px;
      margin: 0;
    }
  }
  .ready-to__win-text {
    margin: 60px 0;
    color: ${color.textWhite};
    font-size: ${sizing.text};
    line-height: 1.57;
  }
  .gatsby-image-wrapper {
    border-radius: ${border.borderRadiusBig};
    img {
      width: 100%;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    padding-bottom: 10px;
    .ready-to__win-btn {
      position: unset;
      margin-top: 21px;
      justify-content: center;
      img {
        right: 28%;
      }
    }
  }
`;

const ReadyToWinBanner = styled('div')`
  position: relative;
  overflow: hidden;
  @media (max-width: ${breakpoints.md}) {
    h3 {
      font-size: ${sizing.heading};
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .gatsby-image-wrapper {
      height: 126px;
      width: 125%;
      left: -30px;
    }
    h3 {
      font-size: ${sizing.mediumHeading};
      top: 8%;
      left: 7%;
      max-width: 150px;
    }
  }
`;

const ReadyToWinContent = styled('div')`
  display: flex;
  justify-content: space-between;
  .left-part_text {
    font-size: ${sizing.text};
    color: ${color.textWhite};
    line-height: 1.57;
    width: 58.334%;
  }
  .gatsby-image-wrapper {
    width: 37.334%;
    border-radius: ${border.borderRadiusBig};
  }
  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    .left-part_text,
    .gatsby-image-wrapper {
      width: 100%;
      margin: 10px 0;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .left-part_text {
      margin: 0 0 30px 0;
    }
    .gatsby-image-wrapper {
      margin: 0;
    }
  }
`;

const ReadyToWinBlock = ({ banner, title, readyToWinBtn, content, leftSideContent, rightSideImage }) => {
  const { readyToWinBtnTitle, arrowIcon } = readyToWinBtn;
  return (
    <ReadyToWinWrapper className="ready-to__win">
      <ReadyToWinBanner className="ready-to__win-banner">
        {banner && <Img fluid={getFile(banner)} alt={banner?.alt_text || title} fadeIn={false} loading="eager" />}
        <h3>{title}</h3>
        <Link to="" className="ready-to__win-btn">
          {readyToWinBtnTitle}
          <img src={arrowIcon} alt="arrow-right" />
        </Link>
      </ReadyToWinBanner>
      <div className="ready-to__win-text">{content}</div>
      <ReadyToWinContent className="ready-to__win-content">
        <div className="left-part_text">{leftSideContent}</div>
        {rightSideImage && (
          <Img fluid={getFile(rightSideImage)} alt={rightSideImage?.alt_text || title} fadeIn={false} loading="eager" />
        )}
      </ReadyToWinContent>
    </ReadyToWinWrapper>
  );
};

export default ReadyToWinBlock;
