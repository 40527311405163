import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import { breakpoints, border, title, sizing, color, wrapper, font } from '../../utils/style';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import { getFile } from '../../utils/system';
import dictionary from '../../utils/dictionary';
import PlayItem from '../../components/Content/CasinoGamesPage/PlayItem';
import WhySbobet from '../../components/shared/WhySbobet';
import RecentPosts from '../../components/Content/RecentPosts';
import arrowIcon from '../../img/IconsSvg/slider-arrow_mobile.svg';
import ReadyToWinBlock from '../../components/Content/SlotPage/ReadyToWinBlock';
import PageDescription from '../../components/Content/SlotPage/PageDescription';
import Slot from '../../icons/svg/Slot';
import CookieDisableComponent from '../../components/shared/CookieDisableError';

const Wrapper = styled('div')`
  p {
    margin-bottom: 1rem;
  }
`;

const GreyWrapper = styled('div')`
  margin: 14px 0 60px;
  .page_description {
    margin: 0;
  }
`;

const FeaturedWrapper = styled('div')`
  position: relative;
  margin: 15px 0 0 0;
  width: 100%;
  ${title};

  .breadcrumbs {
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 30px;
  }

  .gatsby-image-wrapper {
    border-radius: ${border.borderRadiusBig};
    img {
      width: 100%;
    }
  }

  & > h1 {
    left: 10px;
    display: flex;
    align-items: center;
    padding: 0;

    & > img {
      width: 89px;
      height: 89px;
      top: 0;
      left: -90px;
    }

    span {
      margin-left: 10px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    & > h1 {
      & > img {
        width: 50px;
        height: 50px;
        left: -50px;
      }
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    overflow: hidden;
    .breadcrumbs {
      margin: 0;
      top: 7%;
      left: 6%;
    }
    .gatsby-image-wrapper {
      height: 140px;
      width: 125%;
      left: -55px;
    }
    h1 {
      font-size: 24px;
      margin: 0;
      top: 30%;
      svg {
        width: 60px;
        height: 60px;
      }
    }
  }
`;

const PlayItemsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
`;

const Bg = styled('div')`
  background-color: ${color.blockPurple};
  border-radius: ${border.borderRadius};
`;

const RelatedWrapper = styled('div')`
  ${wrapper}
  margin-top: 10px;
  h3 {
    font-size: 1.6rem;
    margin-top: 0;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    display: inline-block;
    vertical-align: top;
    width: 49%;
    margin-bottom: 1rem;
  }
  li:nth-of-type(odd) {
    padding-right: 20px;
  }
  li:nth-of-type(even) {
    padding-left: 20px;
  }
  .recent-excerpt div {
    margin: 10px 0;
    font-size: ${sizing.small};
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 0;
    h3 {
      font-size: 1.2rem;
    }
    ul {
      margin: 10px 0;
    }
    li {
      width: 100%;
      padding: 10px 0 !important;
    }

    & > div {
      padding-top: 0;
      margin-top: 60px;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    margin: 0;
    div {
      margin-top: 0;
    }
    .recent-excerpt div,
    .recent-excerpt p {
      display: inline;
    }
    .recent-excerpt .button {
      padding: 0;
      background: transparent;
      display: block;
      text-align: left;
      line-height: 1.3;
      color: ${color.blue};
      font-weight: ${font.normalWeight};
      font-size: inherit;
      text-decoration: underline;
      &:active,
      &:focus,
      &:hover {
        color: ${color.lightGreen};
        background: none;
      }
    }
  }
`;

const SbobetSlotPage = ({ data, pageContext }) => {
  // Page data
  const { wordpressPage: currentPage } = data;
  const {
    featured_media,
    yoast_meta,
    title: pageTitle,
    path,
    acf: {
      title_top: titleTop,
      desc_top: descTop,
      play_on_group: playGroup,
      play_on_content_title: playOnBlockTitle,
      play_on_content: playOnBlockContent,
      ready_to_win: {
        ready_to_win_title: readyToWinTitle,
        ready_to_win_btn_title: readyToWinBtnTitle,
        ready_to_win_content: readyToWinContent,
        ready_to_win_content_left_side: readyToWinContentLeftSide,
        ready_to_win_content_right_side: readyToWinImageRightSide,
        ready_to_win_image: readyToWinImage,
      },
    },
  } = currentPage;

  // check if current page is casino - game
  const sbobetSlotPage = path === '/sbobet-slot-page/';
  // console.log(sbobetSlotPage, currentPage);
  // Context data
  const { related: relatedIds, breadcrumbs } = pageContext;

  // Meta data
  const {
    yoast_wpseo_title: metaTitle = `${pageTitle}- ${process.env.SITE_NAME_SHORT}`,
    yoast_wpseo_metadesc: metaDesc,
  } = yoast_meta;

  // Media data
  const { alt_text } = featured_media;
  const banner = getFile(featured_media);

  // TODO : enable for dev when be completed
  const enable = true;
  const [localError, setLocalError] = useState(false);

  useEffect(() => {
    try {
      if (window.localStorage) {
        setLocalError(false);
      }
    } catch (error) {
      setLocalError(true);
    }
  });

  if (!localError) {
    return (
      <Layout>
        <SEO title={metaTitle} description={metaDesc} pathname={path} breadcrumbs={breadcrumbs} />
        {enable ? (
          <Wrapper className="sbobet-slot-page__wrapper">
            {/* Banner */}
            <FeaturedWrapper>
              <Breadcrumbs breadcrumbs={breadcrumbs} page_title={pageTitle} />
              {banner && <Img fluid={banner} alt={alt_text || pageTitle} fadeIn={false} loading="eager" />}
              <h1>
                <Slot />
                <span>{pageTitle}</span>
              </h1>
            </FeaturedWrapper>

            {/* Page Description */}
            <PageDescription title={titleTop} description={descTop} />

            {/* Play Where you wants Block */}
            {sbobetSlotPage && (
              <>
                <PlayItemsWrapper className="play-items sbobet-slot-page__play-items">
                  {playGroup?.map((item, index) => (
                    <PlayItem key={index} image={item} title={item.acf.slide_title} />
                  ))}
                </PlayItemsWrapper>
              </>
            )}

            {/* lay on Content */}
            <GreyWrapper>
              <PageDescription title={playOnBlockTitle} description={playOnBlockContent} />
            </GreyWrapper>

            {/* Ready To Win Block */}
            <ReadyToWinBlock
              banner={readyToWinImage}
              title={readyToWinTitle}
              readyToWinBtn={{ readyToWinBtnTitle, arrowIcon }}
              content={readyToWinContent}
              leftSideContent={readyToWinContentLeftSide}
              rightSideImage={readyToWinImageRightSide}
            />

            {/* Whysbobet */}
            <WhySbobet mode="gray" />

            {relatedIds.length > 0 && (
              <Bg>
                <RelatedWrapper>
                  <RecentPosts />
                </RelatedWrapper>
              </Bg>
            )}
          </Wrapper>
        ) : (
          <h1 align="center">{dictionary.comingSoon}</h1>
        )}
      </Layout>
    );
  }

  return (<CookieDisableComponent />);
};

export default SbobetSlotPage;

export const pageQuery = graphql`
  query($id: String!, $related: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      date(formatString: "MMMM DD, YYYY")
      path
      excerpt
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
        title_top
        desc_top
        play_on_content_title
        play_on_content
        seo_content

        play_on_group {
          acf {
            slide_url
            slide_title
          }
          localFile {
            childImageSharp {
              fluid(maxWidth: 150) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          alt_text
        }
        ready_to_win {
          ready_to_win_title
          ready_to_win_btn_title
          ready_to_win_content
          ready_to_win_content_left_side
          ready_to_win_content_right_side {
            localFile {
              childImageSharp {
                fluid(maxWidth: 450) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            alt_text
          }
          ready_to_win_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            alt_text
          }
        }
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1980) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allWordpressPost(filter: { wordpress_id: { in: $related } }, limit: 4) {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 430) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
