import React from 'react';
import styled from '@emotion/styled';
import { color, sizing, breakpoints } from '../../../utils/style';

const WrapperPageDescription = styled('div')`
  margin: 60px 0px;
  h1 {
    font-size: ${sizing.heading};
    margin: 0 0 21px 0;
  }
  .description_content {
    color: ${color.textWhite};
    line-height: 1.57;
    font-size: ${sizing.text};
  }
  @media (max-width: ${breakpoints.xs}) {
    margin: 20px 0 30px;
    h1 {
      margin: 0 0 15px 0;
    }
  }
`;

const PageDescription = ({ title, description }) => (
  <WrapperPageDescription className="page_description sbobet-slot-page__description">
    <h1>{title}</h1>
    <div className="description_content">{description}</div>
  </WrapperPageDescription>
);

export default PageDescription;
