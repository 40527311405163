import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import ButtonLink from '../shared/ButtonLink';
import { color, wrapper, breakpoints, sizing, border, font } from '../../utils/style';
import news from '../../img/news.svg';
import { getFile } from '../../utils/system';

const Bg = styled('div')`
  position: relative;
  @media (max-width: ${breakpoints.md}) {
    padding: 10px 0px;
  }
`;

const ImgWrap = styled('div')`
  display: inline;
  vertical-align: top;
  width: 100%;
  overflow: hidden;

  .gatsby-image-wrapper {
    height: 100%;
    border-radius: ${border.borderRadius};
  }

  @media (max-width: ${breakpoints.xs}) {
    display: block;
    padding: 0;
    margin-bottom: 11px;
    width: auto;
    max-width: 100%;
  }
`;

const NewsWrapper = styled('div')`
  @media (max-width: ${breakpoints.tl}) and (orientation: landscape) {
    a {
      width: calc(33% - 25px) !important;
      a {
        width: 100% !important;
      }
    }
  }

  @media (max-width: ${breakpoints.tl}) and (max-height: ${breakpoints.tl}) and (orientation: portrait) {
    display: grid;
  }

  @media (min-width: ${breakpoints.tl}) {
    display: flex;
    flex-flow: wrap;
    > a {
      width: calc(33% - 6px);
      margin: 15px 14px 70px 0;
      a {
        margin-top: 20px;
      }
      div {
        :nth-of-type(1) {
          max-height: 176px;
        }

        :nth-of-type(3) {
          max-height: 92px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      :nth-of-type(3n) {
        margin-right: 0;
      }

      a {
        width: 100%;

        div a {
          margin: 0;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.md}) and (orientation: portrait) {
    display: grid;

    a div {
      :nth-of-type(1) {
        height: 120px;
        font-size: 13px;
        border-radius: 4px;
      }

      :nth-of-type(3) {
        max-height: 99px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 45px;
      }
      a {
        margin: 0;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) and (min-width: ${breakpoints.sm}) and (orientation: portrait) {
    display: flex;
    flex-flow: wrap;
    > a {
      width: calc(50% - 20px);
      margin: 15px 10px;
      a {
        margin-top: 20px;
      }
      div {
        :nth-of-type(1) {
          max-height: 176px;
          font-size: 13px;
        }

        :nth-of-type(3) {
          max-height: 92x;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      :nth-of-type(3n) {
        margin-right: 0;
      }

      a {
        width: 100%;

        div a {
          margin: 0;
        }
      }
    }
  }
  @media (max-width: ${breakpoints.md}) and (orientation: landscape) {
    a div {
      :nth-of-type(1) {
        font-size: 13px;
        border-radius: 4px;
        margin-bottom: 20px;
      }

      :nth-of-type(3) {
        max-height: 93px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 45px;
      }
      a {
        margin-top: 20px;
      }
    }
  }
`;

const NewsItem = styled('a')`
  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 43px;
  }
`;

const Wrapper = styled('div')`
  padding-bottom: 60px;
  ${wrapper}
  .headerWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    img {
      width: 30px;
      margin-bottom: 0;
    }
    .h3 {
      margin: 0;
    }
    @media (max-width: ${breakpoints.xs}) {
      margin-bottom: 16px;
    }
  }

  .h4 {
    display: block;
    color: ${color.blue};
    font-size: 19px;
    font-weight: ${font.medium};
    margin: 15px 0;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    display: inline-block;
    vertical-align: top;
    width: 49%;
  }
  li:nth-of-type(odd) {
    padding-right: 20px;
  }
  li:nth-of-type(even) {
    padding-left: 20px;
  }
  .recent-excerpt {
    max-height: 95px;
    overflow: hidden;
    div {
      height: 80px;
      font-size: 14px;
      line-height: 1.57;
      color: #f8f8f8;
    }
  }
  .button {
    width: max-content;
    font-size: ${sizing.text};
    font-weight: ${font.headingWeight};
    display: flex;
    span {
      color: ${color.blue};
    }
    & > svg {
      margin-left: 10px;
    }

    &:hover {
      & span {
        color: #90dbfe;
      }

      & > svg {
        & path {
          fill: #90dbfe !important;
        }
      }
    }

    &:active {
      & span {
        color: #0072a3;
      }

      & > svg {
        & path {
          fill: #0072a3 !important;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    h3 {
      font-size: 1.2rem;
    }
    ul {
      margin: 10px 0;
    }
    li {
      width: 100%;
      padding: 10px 0 !important;
    }
  }

  .recent-excerpt div,
  .recent-excerpt p {
    display: inline;
  }
  .recent-excerpt .button {
    padding: 0;
    background: transparent;
    text-align: left;
    line-height: 1.3;
    color: ${color.blue};
    font-weight: ${sizing.normalWeight};
    font-size: inherit;
    text-decoration: underline;
    &:active,
    &:focus,
    &:hover {
      color: ${color.lightGreen};
      background: none;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    padding-bottom: 50px;
    h4 {
      font-size: ${sizing.large};
    }
  }
`;

// REFACTOR
// Spread. I'm sure here more things.
const NewsComponent = ({ data }) => (
  <>
    {data?.map((item) => {
      const { id, node: { slug, featured_media = {}, title, content } = {} } = item;

      return (
        <NewsItem key={slug + id} href={`/${slug}/`}>
          {getFile(featured_media) && (
            <ImgWrap>
              <Img
                fluid={getFile(featured_media)}
                alt={featured_media.alt_text || 'alt'}
                fadeIn={false}
                loading="eager"
              />
            </ImgWrap>
          )}
          <span className="h4">{title}</span>
          <div className="recent-excerpt">
            <div>
              {content && content.substr(0, 270).replace(/<\/p>/g, '').replace(/<p>/g, '')}
              ...
            </div>
          </div>
        </NewsItem>
      );
    })}
  </>
);

const RecentPosts = ({ posts = null }) => (
  <StaticQuery
    query={graphql`
      {
        allWordpressPost(
          filter: { categories: { elemMatch: { name: { eq: "ข่าวฟุตบอล" } } }, status: { eq: "publish" } }
          sort: { fields: id, order: DESC }
          skip: 0
          limit: 6
        ) {
          edges {
            node {
              slug
              title
              excerpt
              content
              featured_media {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1980) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
        placeholder: file(relativePath: { eq: "placeholder.png" }) {
          childImageSharp {
            fluid(maxWidth: 430) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={(data) => {
      const { allWordpressPost: { edges = {} } = {} } = data;

      return (
        <Bg>
          <Wrapper>
            <div className="headerWrapper">
              <img className="iconImage" src={news} width="30" height="30" alt="news" />
              <span className="h3">บทความ</span>
            </div>
            <NewsWrapper>
              <NewsComponent data={posts || edges} />
            </NewsWrapper>

            <ButtonLink
              to="football-news"
              text="อ่านเพิ่มเติม"
              ariaText="อ่านเพิ่มเติม"
              className="button color-blue withoutBgr"
              iconColor={color.darkBlue}
              iconSize="15"
            />
          </Wrapper>
        </Bg>
      );
    }}
  />
);

export default RecentPosts;
